
























































































































































































































































































































































































.entity-activated {
  border: 2px solid #21ba45 !important
}

.entity-single-per-activated {
  border: 2px solid #c62828 !important
}

.collapsible-item {
  overflow: hidden;
  // transition: ease .5s;

  &.expand {
    max-height unset !important
    height: 100%;
    overflow: visible;
  }
}

.container-entity {
  position: relative;
}

.expand-button {
  position: absolute;
  bottom: -13px;
  left: calc(50% - 2.5px);
  z-index 50;
  cursor: pointer;
}

.entity-field-divergent {
  padding-top: 12px;
  border-top: 1px dashed;
}
