@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'


























































































































































































































































































































































.title
  color: $grey-9
  font-size: 1.2rem
  font-weight: 600
